// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-uXmCTC233AJZR4EGLVYypd6n9tatk80",
  authDomain: "jtffgameshop-3bdf9.firebaseapp.com",
  projectId: "jtffgameshop-3bdf9",
  storageBucket: "jtffgameshop-3bdf9.appspot.com",
  messagingSenderId: "66191936728",
  appId: "1:66191936728:web:5993f83e30a2ab1e41dbad",
  measurementId: "G-RM4JYT6PEW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const googleAuth = getAuth(app)
const GoogleProvider = new GoogleAuthProvider()
GoogleProvider.setCustomParameters({
  prompt:'select_account'
})
export {googleAuth, GoogleProvider};
