import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import UserProfile from "../components/UserProfile";
import UserWallet from "../components/UserWallet";
import UserOrder from "../components/UserOrder";
import UserCode from "../components/UserCode";
import UserSecurity from "../components/UserSecurity";
import UserEdit from "../components/UserEdit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUser, logout } from "../redux/features/authSlice";
import AddMoney from "../components/AddMoney";
import PageNotFound from "./PageNotFound";
import { validateResponse } from "../network/apiCalls";
import { fileURL } from "../utils/helpers";
const ProfilePage = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (auth.userToken == null) {
      navigate("/");
    }
  }, [auth.userToken]);
  const tabs = [
    { icon: "fa fa-user", title: "Profile", path: "" },
    { icon: "fa fa-wallet", title: "My Wallet", path: "userWallet" },
    { icon: "fa fa-shopping-cart", title: "My Orders", path: "userOrder" },
    { icon: "fa fa-shopping-cart", title: "My Codes", path: "userCode" },
    { icon: "fa fa-lock", title: "Security", path: "userSecurity" },
  ];
  return (
    <>
      <div className="container-fluid bg-white">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-3">
              <div className="row p-3 mb-4 rounded shadow border">
                <div className="col-4 d-flex justify-content-end ">
                  {auth.userInfo.image != "" ?
                  <img
                    src={auth.userInfo.image}
                    width={"70"}
                  />
                  :
                  <img width="70" height="70" src={fileURL('/static/media/logo-cropped.png')} alt="user-male-circle--v1"/>
                  }
                </div>
                <div className="col-8">
                  <div className="space-y-1">
                    <div className="d-flex items-center justify-start gap-x-3">
                      <p style={{ fontSize: "18px", paddingRight: "10px" }}>
                        Balance:
                        <img
                          width="20"
                          height="20"
                          src={'/static/icons/taka-symbol.png'}
                          style={{ width: "30px", objectFit: "contain" }}
                        />
                        <strong style={{ color: "green", fontWeight: "600" }}>
                          {auth.userInfo.wallet_balance}
                        </strong>{" "}
                      </p>
                    </div>
                    <div className="flex justify-start gap-x-2">
                      <Link
                        to="/profile/addMoney"
                        className="px-4 py-2 text-white rounded shadow"
                        style={{ background: "#db2777" }}
                      >
                        Add Money
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-3 rounded shadow border  d-none d-lg-block">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Account Details
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex flex-column">
                          {tabs.map((item, index) => {
                            return (
                              <LeftTab
                                key={index}
                                icon={item.icon}
                                title={item.title}
                                path={item.path}
                              />
                            );
                          })}
                          <a
                            className={"p-2 text-black "}
                            onClick={() => dispatch(logout())}
                          >
                            <i className={"fa fa-sign-out-alt"} />
                            &nbsp;&nbsp;Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              <Routes>
                <Route path="/" element={<UserProfile />} />
                <Route path="/userWallet" element={<UserWallet />} />
                <Route path="/userOrder" element={<UserOrder />} />
                <Route path="/userCode" element={<UserCode />} />
                <Route path="/userSecurity" element={<UserSecurity />} />
                <Route path="/userEdit" element={<UserEdit />} />
                <Route path="/addMoney" element={<AddMoney />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfilePage;

const LeftTab = ({ path, icon, title }) => {
  const location = useLocation();
  let currentTab = location.pathname.split("/")[2] ?? "";

  return (
    <Link
      to={path}
      className={"p-2 text-black " + (currentTab == path ? "border" : "")}
    >
      <i className={icon} />
      &nbsp;&nbsp;{title}
    </Link>
  );
};
