import { useEffect, useState } from "react";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import HomePage from "./HomePage";
import ApplyPage from "./ApplyPage";
import LoginPage from "./LoginPage";
import BlogPage from "./BlogPage";
import RegisterPage from "./RegisterPage";

import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlogPostDetails from "./BlogPostDetails";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Category from "./Category";
import ProfilePage from "./Profile";
import TermsConditionPage from "./TermsConditionPage";
import { useDispatch } from "react-redux";
import { getMasterCategory } from "../redux/features/websiteSlice";
import UserProfile from "../components/UserProfile";
import Voucher from "./Voucher";
import PageNotFound from "./PageNotFound";
import SiteModals from "../components/Modals";
import {  getShopStatusApi } from "../network/apiCalls";
import { fileURL } from "../utils/helpers";

const MainSite = () => {
  const [shopStatus,setShopStatus] = useState(null);

  const disptach = useDispatch();
  const fetchShopStatus =  async () => {
    try{
      let response = await getShopStatusApi()
      if(response.status == 200){
        let data = response.data;
        setShopStatus(data)
      }
    }catch(e){
     console.log(e)
    }
  }
  useEffect(() => {
    disptach(getMasterCategory());
    fetchShopStatus();
  }, []);
  return (
    <>
      <ScrollToTop />
      <div className="container-fluid bg-light p-2">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <a href="https://wa.me/+8801720452790" className="text-black me-3">
                <img src={"/static/icons/WhatsApp_logo.png"} alt="Logo" style={{height:30}}/>
              </a>
            </div>

            <a href="https://play.google.com/store/apps/details?id=com.awt.jtffgameshop" className="text-white text-decoration-none">
              <img
                src={fileURL('/static/icons/google-play.png')}
                alt="Download"
                style={{width:120}}
              />
            </a>
          </div>
        </div>
      </div>
      <SiteHeader />
      <main className="main-area bg-light pb-5 overflow-hidden">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/voucher-code"
            element={<HomePage type="voucher-code" />}
          />
          <Route
            path="/player-id-topup"
            element={<HomePage type="player-id-topup" />}
          />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/voucher/:id" element={<Voucher />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/profile/*" element={<ProfilePage />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/terms-and-condition" element={<TermsConditionPage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogPostDetails />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
      <SiteFooter />
      {(shopStatus && shopStatus.popup_status == '1') ? (
        <SiteModals id={'example1'} text={shopStatus.popup_text} image={shopStatus.popup_image} />
      ):null}
      {(shopStatus && shopStatus.popup_status2 == '1') ? (
        <SiteModals id={'example2'} text={shopStatus.popup_text2} image={shopStatus.popup_image2}/>
      ):null}
      <ToastContainer />
    </>
  );
};
export default MainSite;
