import { useEffect, useRef, useState } from "react";
import { getCategoriesApi } from "../network/apiCalls";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CategoryBox from "../components/CategoryBox";
import PlanPicker from "../components/PlanPicker";
const Category = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const websiteData = useSelector((state) => state.websiteData);
  const [master, setMaster] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const fetchCategory = async (categoryID) => {
    try {
      let response = await getCategoriesApi(categoryID, 1);
      if (response.status == 200 && response.data.status == true) {
        setCategories(response.data.data);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    setMaster(websiteData.masterCategory.find((item) => item.id == id));
    fetchCategory(id);
  }, [websiteData,id  ]);

  const planBoxRef = useRef(null);
  const handleClick = (category) => {
    setActiveCategory(category);
    planBoxRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (master == undefined) {
    return null;
  }
  return (
    <>
      <div className="container-fluid bg-white">
        <div className="container py-5">
          <div className="d-flex">
            <img
              src={master.image}
              style={{ height: "140px" }}
              className="rounded"
            />
            <div className="p-3">
              <div className="d-flex flex-wrap align-items-center">
                <small className="fs-2 text-black me-2">{master.cname}</small>{" "}
                <Link to={"/"}>{"(Player ID Topup)"}</Link>
              </div>
              <div className="d-flex align-items-center">
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/plasticine/100/000000/lightning-bolt--v1.png"
                  alt="lightning-bolt--v1"
                />
                <span className="text-black">5-10min</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        {/* Category list */}
        <div className="row mx-lg-5 mx-2 mt-3">
          {categories &&
            categories.map((item, index) => {
              return (
                <CategoryBox
                  key={index}
                  data={item}
                  handleClick={handleClick}
                  isSelected={item.id == activeCategory?.id}
                />
              );
            })}
        </div>
      </div>
      <div ref={planBoxRef} className="">
        {activeCategory && (
          <>
            {/* <div className="container my-3 bg-white py-1 rounded">
            {master.cname} <i className="fa fa-arrow-right">  </i> <span className="text-black">{activeCategory.cname}</span>
          </div> */}
            <PlanPicker planId={activeCategory.id} title={activeCategory.cname} />
          </>
        )}
      </div>
    </>
  );
};
export default Category;
