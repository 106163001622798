import axios from "axios";
import { toast } from "react-toastify";
const BASEURL = "https://jtff.romiosportsclub.com/api"
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
// axios.defaults.headers.post["Enviroment"] = "DEV";
function getAuthToken() {
  if (localStorage.getItem("authState")) {
    try {
      let token = JSON.parse(localStorage.getItem("authState")).userToken;
      return token;
    } catch (err) {
      console.log("Error:", err);
      return "";
    }
  }
  return "";
}

function commonHeaders(){
  return {
    Authorization: getAuthToken(),
    "Content-Type":"multipart/form-data",
    "Client":'web',
  }
}

export const registerUserApi = async (userData) => {
  console.warn("Request:", userData);
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/auth/register_user",
    data: userData,
  });
  return resposne;
};

export const userWalletListApi = async () => {
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/user/get_wallet_request",
    data: {},
    headers:commonHeaders() 
  });
  return resposne;
};

export const userOrderListApi = async () => {
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/user/get_request_list",
    data: {},
    headers:commonHeaders() 
  });
  return resposne;
};

export const userCodeListApi = async () => {
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/user/get_voucher_history",
    data: {},
    headers:commonHeaders() 
  });
  return resposne;
};

export const loginUserApi = async (loginDetails) => {
  console.warn("Request:", loginDetails);
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/auth/login",
    data: loginDetails,
    headers:commonHeaders() 
  });
  return resposne;
};

export const updateUserApi = async (userData) => {
  console.warn("userDate:", userData);
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/user/update_user",
    data: userData,
    headers:commonHeaders() 
  });
  return resposne;
};

export const fetchUserApi = async () => {
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/user/user_data",
    data: {},
    headers:commonHeaders() 
  });
  return resposne;
};

export const getMasterCategoryApi = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/user/master_category_list",
    headers:commonHeaders()
  });
  return resposne;
};

export const getEventsApi = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/user/event_list",
    headers:commonHeaders()
  });
  return resposne;
};

export const getCategoriesApi = async (categoryId,userId) => {
  let resposne = await axios({
    method: "post",
    data:{master_category:categoryId,user_id:userId},
    url: BASEURL + "/user/category_list",
  });
  return resposne;
};

export const getVouchersApi = async (categoryId) => {
  let resposne = await axios({
    method: "post",
    data:{mcategory_id:categoryId},
    url: BASEURL + "/user/auto_delivery_vouchers_list",
  });
  return resposne;
};
export const getWatchUrl = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/user/get_senders",
  });
  return resposne;
};
export const getShopStatusApi = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/user/shop_status",
  });
  return resposne;
};
export const getPlanListApi = async (id) => {
  let resposne = await axios({
    method: "post",
    data:{category_id:id},
    url: BASEURL + "/user/plan_list/",
    // headers:commonHeaders()
  });
  return resposne;
};

export const requestApi = async (requestData) => {
  let resposne = await axios({
    method: "post",
    data:requestData,
    url: BASEURL + "/user/request/",
    headers:commonHeaders()
  });

  return resposne;
};
export const buyVoucherApi = async (requestData) => {
  let resposne = await axios({
    method: "post",
    data:requestData,
    url: BASEURL + "/user/buy_voucher/",
    headers:commonHeaders()
  });
  return resposne;
};

export const addMoney = async (amount) => {
  let resposne = await axios({
    method: "post",
    url: BASEURL + "/user/wallet_request_webview",
    data:{amount:amount, txn_id:Date.now() + "" + Math.floor((Math.random() * 10000))},
    headers:commonHeaders()
  });
  return resposne;
};

export const requestDirectPayApi = async (amount,requestData) => {
  let data = {};
  data.amount = amount
  data.requestData = requestData;
  data.txn_id = Date.now() + "" + Math.floor((Math.random() * 10000));

  let resposne = await axios({
    method: "post",
    url: BASEURL + "/user/wallet_request_webview",
    data:data,
    headers:commonHeaders()
  });
  return resposne;
};
export const blogsApi = async () => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/user/blog_list",
    headers:commonHeaders()
  });
  return resposne;
};
export const blogDetailsApi = async (id) => {
  let resposne = await axios({
    method: "get",
    url: BASEURL + "/user/blog/"+id,
    headers:commonHeaders()
  });
  return resposne;
};
export const validateResponse = (response) => {
  if(response.status == 401){
    toast.error("Session Expired");
    window.location.href = window.location.origin;
    //localStorage.removeItem('authState');
    //location.reload();
  }else{
  }
}

