import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loginUser, registerUser } from "../redux/features/authSlice";
import { useState } from "react";
import GoogleButton from "react-google-button";

import { googleAuth, GoogleProvider } from "../firebase/config";
import {  signInWithPopup } from "firebase/auth";

const RegisterPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passView, setPassView] = useState(false);
  const onSubmit = async (data) => {
    try {
      console.log(data);
      const result = await dispatch(registerUser(data)).unwrap();
      reset();
      toast.success("Registered successfully");
      navigate("/");
      console.log("log 1", result);
    } catch (error) {
      toast.error(error);
      console.log("log 2", error);
    }
  };

  async function loginWithGoogle(){
    try{
      let res = await signInWithPopup(googleAuth,GoogleProvider);
      let rawInfo = JSON.parse(res._tokenResponse.rawUserInfo);
      let userData  = {email:rawInfo.email,password:rawInfo.id};
      try {
        const result = await dispatch(loginUser(userData)).unwrap();
        toast.success("Logged in successfully");
        navigate("/");
      } catch (error) {
        // console.log("login attmped",error);
        if(error.status == false && error.userExists === false){
          userData.name = res.user.displayName
          userData.mobile_no = '9999999999';
          dispatch(registerUser(userData)).unwrap().then((success)=>{
            dispatch(loginUser(userData)).unwrap().then((success)=>{
              toast.success("Logged in successfully");
            navigate('/')
            },(failed)=>{
              console.log("Login Failed",failed);
            });
            
          },(failed)=>{
            console.log("Failed to Register",failed);
          });
        }else{
          toast.error("Email not registered for Google Login");
        }        
      }

    }catch(e){
      console.log(e);
      toast.error(e.message)
    }

  }

  return (
    <>
      <main className="main-area">
        <div
          data-elementor-type="wp-page"
          data-elementor-id="57"
          className="elementor elementor-57"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-17b794a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="17b794a"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-61c4005"
                data-id="61c4005"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-1b94da1 elementor-widget elementor-widget-hero-banner"
                    data-id="1b94da1"
                    data-element_type="widget"
                    data-widget_type="hero-banner.default"
                  >
                    <div className="elementor-widget-container">
                      {/* <!-- banner-area --> */}
                      <section
                        className="banner-area mx-2 mx-md-0 pt-3 pt-md-5"
                        style={{
                          backgroundImage:
                            "url(https://img.freepik.com/free-vector/paper-style-white-monochrome-background_23-2149014827.jpg)",
                        }}
                      >
                        <div className="container mb-5">
                          <div className="row justify-content-center">
                            <div
                              className="col-md-5 pe-0 shadow rounded  mb-5"
                              style={{ background: "white" }}
                            >
                              <div className="form-left h-100 py-5 px-3 px-md-5 apply-box">
                                <h3 className="mb-3 text-black">Register</h3>
                                <form
                                  action=""
                                  className="row g-4"
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <div className="col-12 form-group">
                                    <label className="text-black">
                                      Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="">
                                      <input
                                        type="text"
                                        className={
                                          "form-control " +
                                          (errors.name ? "danger-border" : "")
                                        }
                                        placeholder="Enter Name"
                                        {...register("name", {
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 form-group">
                                    <label className="text-black">
                                      Email
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="">
                                      <input
                                        type="text"
                                        className={
                                          "form-control " +
                                          (errors.email ? "danger-border" : "")
                                        }
                                        placeholder="Enter Email"
                                        {...register("email", {
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 form-group">
                                    <label className="text-black">
                                      Mobile Number
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="">
                                      <input
                                        type="text"
                                        className={
                                          "form-control " +
                                          (errors.mobile_no
                                            ? "danger-border"
                                            : "")
                                        }
                                        placeholder="Enter Mobile number"
                                        {...register("mobile_no", {
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 form-group">
                                    <label className="text-black">
                                      Password
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-text bg-black text-white">
                                        <i className="fa fa-key"></i>
                                      </div>
                                      <input
                                        type={passView ? "text" : "password"}
                                        className={
                                          "form-control " +
                                          (errors.password
                                            ? "danger-border"
                                            : "")
                                        }
                                        placeholder="Enter Password"
                                        {...register("password", {
                                          required: true,
                                        })}
                                      />
                                      <div
                                        className="input-group-text bg-black text-white"
                                        onClick={() => setPassView(!passView)}
                                      >
                                        <i
                                          className={
                                            "fa fa-eye" +
                                            (passView ? "" : "-slash")
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                 
                                  <div className="col-lg-12">
                                    <button className="btn btn-primary">
                                      Submit
                                    </button>
                                    
                                  </div>
                                  
                                </form>
                                <div className="col-lg-12 mt-5 mt-md-4" align="center">
                                    <GoogleButton onClick={()=>loginWithGoogle()}/>                                   
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* <!-- banner-area-end --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default RegisterPage;
