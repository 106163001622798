import { useEffect, useState } from "react";
import SimpleSlider from "../components/SimpleSlider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CategoryBox from "../components/CategoryBox";
import { getPathByMaster, isVoucher } from "../utils/helpers";

const HomePage = (props) => {  
  const websiteData = useSelector((state) => state.websiteData);
  const navigate = useNavigate();
  const handleClick = (mCat) =>{
      navigate(getPathByMaster(mCat)) 
  }
  const type = props.type;
  let list = websiteData.masterCategory;
  if(type && type == 'voucher-code'){
    list = list.filter((item)=>isVoucher(item.cname));
  }else if(type && type == 'player-id-topup'){
    list = list.filter((item)=>isVoucher(item.cname)==false);
  }

  return (
    <>
      <SimpleSlider />
      <div className="row mx-lg-5 mx-2 mt-3">
        {list && list.map((item, index) => {
          return (
           <CategoryBox key={index} data={item} handleClick={handleClick}/>
          );
        })}
      </div>
    </>
  );
};
export default HomePage;
