import { useEffect, useState } from "react";
import { blogsApi } from "../network/apiCalls";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import BlogPost from "../components/BlogPost";
const BlogPage = () => {
  const [blogs, setBLogs] = useState([]);
  const fetchBlogs = async () => {
    try {
      let response = await blogsApi();
      if (response.status == 200 && response.data.status) {
        setBLogs(response.data.data);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="breadcrumb-content">
            <h2 className="title">Blogs</h2>
          </div>
        </div>
      </div>

      <div className="row">
        {blogs.map((item,index)=>{
          return <BlogBox blog={item} key={index}/>
        })}
      </div>
    </div>
  );
};

export default BlogPage;

const BlogBox = ({blog}) => {
  const navigate = useNavigate()
  const imgURL = blog.type == 'image' ? blog.image : 'https://img.youtube.com/vi/'+blog.youtube_id+'/0.jpg'
  return (
    <div className="col-lg-4">
          <div class="card mt-3 shadow" onClick={()=>navigate('/blog/'+blog.blog_id)}>
            <div style={{height:200}} className="bg-dark">
                <img src={imgURL} class="card-img-top" alt={blog.title} style={{height:'100%',objectFit:'contain'}} />
            </div>
            <div class="card-body">
              <h5 class="card-title">{blog.title}</h5>
            </div>
          </div>
        </div>
  )
}

