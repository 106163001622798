import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BlogPost from "../components/BlogPost";
import { blogDetailsApi } from "../network/apiCalls";
import { toast } from "react-toastify";
const BlogPostDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);

  const fetchBlog = async (id) => {
    try {
      let response = await blogDetailsApi(id);
      if (response.status == 200 && response.data.status) {
        setBlog(response.data.data);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchBlog(id);
  }, []);
  if(blog == null)
    return <h4 className="text-center mt-5">Loading...</h4>
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-12">
        <div class="card mb-3">
          <div style={{height:350}}  className="bg-dark">
          {blog.type == 'image' ?
            (<img src={blog.image} class="card-img-top" alt="..." style={{height:'100%',objectFit:'contain'}}/>): 
            (<YoutubeVideo id={blog.youtube_id}/>)}
          </div>
          
          <div class="card-body">
            <h5 class="card-title">{blog.title}</h5>
            <p class="card-text" dangerouslySetInnerHTML={{__html:blog.content}}></p>
            <p class="card-text"><small class="text-muted">Posted on {blog.created_at}</small></p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};
export default BlogPostDetails;
const YoutubeVideo =  ({id}) => {
  return <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/"+id+"?si=s4AvZMpiOtkugiKB&amp;controls=0"} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

}