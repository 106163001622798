import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchUserApi,
  loginUserApi,
  registerUserApi,
  updateUserApi,
} from "../../network/apiCalls";

const initialState = {
  loading: false,
  userInfo: {}, // for user object
  userToken: null, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
};

export const loginUser = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const resposne = await loginUserApi(userData);
      console.log("resp ", resposne);
      if (resposne.status == 200 && resposne.data.status == true){
        let uData = resposne.data.data;
        if(userData?.photoURL){
            uData.photoURL = userData.photoURL
        }
        return thunkAPI.fulfillWithValue(uData);
      }
      else return thunkAPI.rejectWithValue(resposne.data);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/update",
  async (userData, thunkAPI) => {
    try {
      const resposne = await updateUserApi(userData);
      console.log("resp ", resposne);
      if (resposne.status == 200 && resposne.data.status == true)
        return thunkAPI.fulfillWithValue(userData);
      else return thunkAPI.rejectWithValue(resposne.data.message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    try {
      const resposne = await registerUserApi(userData);
      console.log("resp ", resposne);
      if (resposne.status == 200 && resposne.data.status == true)
        return thunkAPI.fulfillWithValue(resposne.data.message);
      else return thunkAPI.rejectWithValue(resposne.data.message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const fetchUser = createAsyncThunk(
  "auth/fetch",
  async (_, thunkAPI) => {
    try {
      const resposne = await fetchUserApi();
      console.log("resp ", resposne);
      if (resposne.status == 200 && resposne.data.status == true)
        return thunkAPI.fulfillWithValue(resposne.data.data);
      else return thunkAPI.rejectWithValue(resposne.data.message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.userToken = null;
      state.userInfo = {};
    },
  },
  extraReducers: (builer) => {
    // Login
    builer.addCase(loginUser.pending, (state) => {
      console.log("Pending");
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builer.addCase(loginUser.fulfilled, (state, action) => {
      console.log("fulfilled:", action.payload);
      state.loading = false;
      state.success = true;
      state.userInfo = action.payload;
      state.userToken = action.payload.authToken;
    });
    builer.addCase(loginUser.rejected, (state, action) => {
      console.log("rejected:", action.payload.message);
      state.loading = false;
      state.error = action.payload.message;
    });

    //Update
    builer.addCase(updateUser.pending, (state) => {
      console.log("Pending");
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builer.addCase(updateUser.fulfilled, (state, action) => {
      console.log("fulfilled:", action.payload);
      state.loading = false;
      state.success = true;
      state.userInfo.name = action.payload.name;
      state.userInfo.mobile_no = action.payload.mobile_no;
    });
    builer.addCase(updateUser.rejected, (state, action) => {
      console.log("rejected:", action.payload);
      state.loading = false;
      state.error = action.payload;
    });

    // Register
    builer.addCase(registerUser.pending, (state) => {
      console.log("Pending");
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builer.addCase(registerUser.fulfilled, (state, action) => {
      console.log("fulfilled:", action.payload);
      state.loading = false;
      state.success = action.payload;
    });
    builer.addCase(registerUser.rejected, (state, action) => {
      console.log("rejected:", action.payload);
      state.loading = false;
      state.error = action.payload;
    });

        // Register
        builer.addCase(fetchUser.pending, (state) => {
          console.log("Pending");
          state.loading = true;
          state.error = null;
          state.success = false;
        });
        builer.addCase(fetchUser.fulfilled, (state, action) => {
          console.log("fulfilled:", action.payload);
          state.loading = false;
          state.success = true;
          state.userInfo = action.payload
        });
        builer.addCase(fetchUser.rejected, (state, action) => {
          console.log("rejected:", action.payload);
          if(action.payload == 'Token invalid or missing'){
            state.userToken = null
            state.userInfo = {}
          }else{
            state.loading = false;
            state.error = action.payload;
          }
          
        });

  },
});
const authReducer = authSlice.reducer;
export const { processStart, processEnd, logout } = authSlice.actions;
export default authReducer;
