import {
  getWatchUrl,
  userCodeListApi,
  validateResponse,
} from "../network/apiCalls";
import { useEffect, useState } from "react";
import getStatus from "../utils/helpers";
import { toast } from "react-toastify";
const UserCode = () => {
  const [codes, setCodes] = useState([]);
  const [watchUrl, setWatchUrl] = useState("");
  const fetchCodesHistory = async () => {
    try {
      let response = await userCodeListApi();
      if (response.status == 200 && response.data.status == true) {
        let res = response.data.data.sort(function (a, b) {
          if (parseInt(a.order_id) > parseInt(b.order_id)) return -1;
          else if (parseInt(a.order_id) < parseInt(b.order_id)) return 1;
          else return 0;
        });
        setCodes(res);
      }
    } catch (e) {
      validateResponse(e.response);
    }
  };
  const fetchWatchUrl = async () => {
    try {
      let response = await getWatchUrl();
      if (response.status == 200 && response.data.status == true) {
        setWatchUrl(response.data.upay);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchCodesHistory();
    fetchWatchUrl();
  }, []);
  return (
    <>
      <div className="row">
        <div className="mx-lg-5 rounded shadow border">
          <p className="text-black fs-4">
            <i className=" fa fa-solid fa-shopping-cart"></i> &nbsp;&nbsp;My
            Codes
          </p>
          <div className="table-wrapper">
            <div id="table-scroll">
              {codes.map((res, index) => {
                let codes = res.codes.split(',')
                return (
                  <table
                    key={index}
                    className="border text-black w-100"
                    cellPadding={5}
                  >
                    <tbody>
                      <tr>
                        <th>SerialNo</th>
                        <td>{res.order_id}</td>
                      </tr>
                      <tr>
                        <th>DateTime</th>
                        <td>{res.created_at}</td>
                      </tr>
                      <tr>
                        <th>Package</th>
                        <td>{res.voucher_name}</td>
                      </tr>
                      <tr>
                        <th>Price</th>
                        <td>{res.price * codes.length}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td className="fs-7">{getStatus(1)}</td>
                      </tr>
                      <tr>
                        <th colSpan={2}>Code</th>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <ul className="list-group">
                            {codes.map((code,index2)=><li key={index2} className="list-group-item d-flex justify-content-between align-items-center py-1 my-1" style={{background:'#ccdaed'}}>
                              <span>{code}</span>
                              <button type="button" className="btn btn-xs btn-primary ms-2" onClick={() => {
                                  navigator.clipboard.writeText(code);
                                  toast.success("Coppied.");
                                }}><i className="fa fa-copy"></i></button>
                             </li>)}
                            
                          </ul>
                        </td></tr>
                      <tr>
                        <td>
                          <button
                            className="btn btn-xs btn-secondary" style={{whiteSpace:'nowrap'}}
                            onClick={() => {
                              navigator.clipboard.writeText(res.codes);
                              toast.success("Coppied.");
                            }}
                          >
                             Copy Code
                          </button>
                        </td>
                        <td>
                          <a href={watchUrl} className="btn btn-xs btn-primary">
                            Redeem Code
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCode;
