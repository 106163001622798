import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateUser } from "../redux/features/authSlice";
import { toast } from "react-toastify";
import { addMoney, validateResponse } from "../network/apiCalls";

const AddMoney = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    console.log(data);
    try{
      let response = await addMoney(data.money)
      let respData = response.data
      if(respData.status == true){
        window.open(respData.payment_url)
      }else{
        toast.error(respData.message)
      }
    }catch(e){
      console.log(e)
      toast.error(e.message)
      validateResponse(e.response);
    }
    
  };
  return (
    <>
      {" "}
      <div className="row">
        <div className="mx-lg-5 rounded shadow border">
          <p className="text-black fs-4">Request Add Money</p>
          <form action="" className="row g-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12">
              <label className="text-black">
                Add Amount
                <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                placeholder="How Much ?"
                type="number"
                {...register("money", {
                  required: { value: true, message: "Required Field" },
                  valueAsNumber:{value:true, message: "Only Numbers are allowed "},
                })}
              />
              <p className="text-danger m-0">{errors.money?.message}</p>
            </div>

            <div className="col-lg-12  my-2">
              <button className="btn btn-primary">Add Money</button>
            </div>
          </form>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/xiv0kYKlcXM?si=FUt9YCB3Rvyug_DY"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default AddMoney;
